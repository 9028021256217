import styled from '@emotion/styled';

const titleFontFamily = "'Allura', serif";

export const H1 = styled.h1`
  font-family: ${titleFontFamily};
  font-size: ${props => props.theme.fonts.s2};
  font-weight: normal;
  @media (min-width: 768px) {
    font-size: ${props => props.theme.fonts.s1};
  }
`;

export const H2 = styled.h2`
  font-family: ${titleFontFamily};
  font-size: ${props => props.theme.fonts.s3};
  font-weight: normal;
  &.gite-subtitle {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    font-size: ${props => props.theme.fonts.s2};
  }
`;

export const H3 = styled.h3`
  font-family: ${titleFontFamily};
  font-weight: normal;
  font-size: ${props => props.theme.fonts.s3};
`;

export const H4 = styled.h4`
  font-family: ${titleFontFamily};
  font-weight: normal;
  font-size: ${props => props.theme.fonts.s4};
`;

export const DecoratedTitle = styled(H2)`
  text-align: center;
  font-size: ${props => props.theme.fonts.s3};
  margin: ${props => props.theme.spaces.md} auto
    ${props => props.theme.spaces.sm};
  @media (min-width: 500px) {
    font-size: ${props => props.theme.fonts.s2};
    &::before,
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      border-bottom: solid 1px;
      opacity: 0.3;
      margin: 0 15px;
    }
  }
`;

export const SubTitleHome = styled(H3)`
  margin: 0 auto ${props => props.theme.spaces.sm};
  font-size: ${props => props.theme.fonts.s3};
  text-align: center;
  @media (min-width: 500px) {
    &::before,
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      border-bottom: solid 1px;
      opacity: 0.3;
      margin: 0 15px;
    }
  }
`;

export const PageTitle = styled(H1)`
  line-height: 1;
  text-align: center;
  margin: ${props => props.theme.spaces.lg} auto
    ${props => props.theme.spaces.sm};
  @media (min-width: 450px) {
    &::before,
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      border-bottom: solid 1px;
      opacity: 0.3;
      margin: 0 15px;
    }
  }
`;
