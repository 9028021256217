import React from 'react';
import styled from '@emotion/styled';

const StyledExternalLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.link};
  .app-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  &:hover {
    color: ${props => props.theme.colors.link};
  }
`;

const StyledExternalLinkButton = styled(StyledExternalLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  border: 1px solid ${({ theme }) => theme.colors.green};
  color: #fff;
  height: 34px;
  padding: 0 ${({ theme }) => theme.spaces.sm};
  margin: ${({ theme }) => theme.spaces.md} auto;
  border-radius: ${({ theme }) => theme.borders.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.boxShadowLight};
  transition: color 0.3s, background-color 0.3s;
  :hover,
  :focus {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.green};
    cursor: pointer;
  }
`;

const StyledBookinkButton = styled(StyledExternalLinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 200px;
  margin-top: ${({ theme }) => theme.spaces.lg};
  background-color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  font-size: 1.3rem;
  :hover,
  :focus {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.blue};
    cursor: pointer;
  }
`;

export const ExternalLink = ({
  href,
  text,
  icon,
  button,
  bookingButton,
  style,
}) => {
  if (button) {
    return (
      <StyledExternalLinkButton
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon && <span className="app-icon">{icon}</span>}
        <span>{text}</span>
      </StyledExternalLinkButton>
    );
  } else if (bookingButton) {
    return (
      <StyledBookinkButton
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon && <span className="app-icon">{icon}</span>}
        <span>{text}</span>
      </StyledBookinkButton>
    );
  } else {
    return (
      <StyledExternalLink
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon && <span className="app-icon">{icon}</span>}
        <span>{text}</span>
      </StyledExternalLink>
    );
  }
};
